import { suspensionStore } from '@/services/Units/Tabs/LateFeeSuspension/SuspensionModal/store'
import $ from 'jquery'
import { notifyError } from '@/services/notify'
import _get from 'lodash/get'
import moment from 'moment'

export const methods = {
  async reload() {
    this.loading = true

    if (this.suspension && this.suspension != undefined) {
      //Update
      this.buttonText = 'Update'
      this.formData.lateFeeSuspensionID = this.suspension.lateFeeSuspensionID
      this.formData.ownerID = this.suspension.ownerID
      this.formData.startDate = this.suspension.startDate
      this.formData.endDate = this.suspension.endDate
      this.formData.reason = this.suspension.reason
      this.formData.addedBy =
        this.suspension.createdByIdentityInformation &&
        this.suspension.createdByIdentityInformation != undefined
          ? this.suspension.createdByIdentityInformation.name
          : null

      var startDate = this.suspension.startDate
      if (startDate) {
        this.$refs.startDateComponent.selected = new Date(this.suspension.startDate)
      }

      var endDate = this.suspension.endDate
      if (endDate) {
        this.$refs.endDateComponent.selected = new Date(this.suspension.endDate)
      }

      this.loading = false
    } else {
      this.buttonText = 'Add'
    }

    this.loading = false
  },

  open() {
    this.formData = {
      ownerID: 0,
      startDate: null,
      endDate: null,
      reason: ''
    }
    this.isOpen = true
    this.loading = false
  },

  async submitSuspension() {
    if (this.formData && !this.formData.startDate) {
      notifyError('Please select a start date. The start date is a required field.')
      return
    }

    if (this.formData && !this.formData.endDate) {
      notifyError('Please select a end date. The end date is a required field.')
      return
    }

    if (this.formData && this.formData != undefined) {
      if (this.formData.lateFeeSuspensionID && this.formData.lateFeeSuspensionID > 0) {
        await this.updateSuspension()
      } else {
        await this.addSuspension()
      }
    }
  },

  async addSuspension() {
    if (!this.formData || this.formData == undefined) {
      notifyError('There was a problem processing this late fee submission.')
      return
    }

    let dateMessage = ''
    if (!this.formData.startDate) {
      dateMessage = 'Please enter a valid start date.'
    }

    if (!this.formData.endDate) {
      dateMessage = dateMessage + 'Please enter a valid end date.'
    }

    if (!moment(this.formData.startDate, true).isValid()) {
      dateMessage = dateMessage + 'The start date is not valid'
    }

    if (!moment(this.formData.endDate).isValid()) {
      dateMessage = dateMessage + 'The end date is not valid'
    }

    if (moment(this.formData.endDate) < moment(this.formData.startDate)) {
      dateMessage = dateMessage + 'The end date must be greater than the start date.'
    }

    if (dateMessage !== '') {
      notifyError(dateMessage)
      return
    }

    this.loading = true

    if (this.isDebug == true) console.debug('addInsurance params=' + JSON.stringify(this.formData))

    await suspensionStore.dispatch('addSuspension', {
      ownerID: this.ownerID,
      reason: _get(this.formData, 'reason', ''),
      startDate: this.formData.startDate
        ? moment(this.formData.startDate).format('YYYY-MM-DD')
        : null,
      endDate: this.formData.endDate ? moment(this.formData.endDate).format('YYYY-MM-DD') : null,
      done: ({ details }) => {
        this.loading = false
        if (details && details != undefined) {
          if (this.isDebug == true)
            console.debug(
              'details details.lateFeeSuspensionID=' +
                JSON.stringify(details) +
                ', lateFeeSuspensionID=' +
                details.lateFeeSuspensionID
            )

          this.leaveForm()
        }
      }
    })

    this.loading = false
  },

  async updateSuspension() {
    if (
      !this.formData ||
      this.formData == undefined ||
      !this.formData.lateFeeSuspensionID ||
      this.formData.lateFeeSuspensionID == undefined
    ) {
      notifyError('There was a problem updating the selected late fee suspension.')
      return
    }

    this.loading = true

    const payload = {
      lateFeeSuspensionID: _get(this.formData, 'lateFeeSuspensionID', 0),
      ownerID: _get(this.formData, 'ownerID', 0),
      reason: _get(this.formData, 'reason', ''),
      startDate: this.formData.startDate
        ? moment(this.formData.startDate).format('YYYY-MM-DD')
        : null,
      endDate: this.formData.endDate ? moment(this.formData.endDate).format('YYYY-MM-DD') : null
    }

    await suspensionStore.dispatch('updateSuspension', {
      payload: payload,
      done: ({ details }) => {
        this.loading = false
        if (details) {
          this.leaveForm()
        }
      }
    })

    this.loading = false
  },

  leaveForm() {
    // auto-close modal
    this.$emit('update:toggle', (this.toggle = false))
    $('#clickclose').click()
    this.loading = false
  }
}
