/*
  import { suspensionList } from '@/services/Units/Tabs/LateFeeSuspension/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import _get from 'lodash/get'

import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await suspensionList.dispatch ('getInsuranceList', {
      hoaId
    });
  */
  async getSuspensionList({}, { hoaID, ownerID }) {
    console.log('in getSuspensionList...')
    try {
      const result = await kms.get('/AccountsReceivable/LateFeeSuspension/List', {
        params: {
          hoaID,
          ownerID
        }
      })

      if (isDebug == true) console.debug('getSuspensionList log=' + JSON.stringify(result))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The late fee suspension list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async deleteSuspension({ dispatch }, { lateFeeSuspensionID, done }) {
    try {
      const path = `/AccountsReceivable/LateFeeSuspension/${lateFeeSuspensionID}?forceDelete=false`

      const results = await kms.delete(path)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted the selected late fee suspension.`)
        done()
      } else {
        notifyError('There was a problem deleting the selected late fee suspension.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem deleting the selected late fee suspension.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const suspensionList = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
