export function data() {
  return {
    formData: {
      lateFeeSuspensionID: 0,
      startDate: null,
      endDate: null,
      reason: '',
      addedBy: ''
    },
    isDebug: true,
    newId: null,
    isOpen: false,
    loading: true,
    openOnFocus: true,
    showWeekNumber: false,
    buttonText: 'Add',
    styles: {
      datePickerContainer: { display: 'flex', alignItems: 'end' },
      datePicker: { margin: 0, marginRight: '12px' }
    }
  }
}
