<template>
  <div>
    <button
      class="button is-info is-light is-small is-outline is-rounded"
      @click="loadUpdateSuspension(suspension)"
    >
      Edit</button
    >&nbsp;
    <button
      class="button is-info is-light is-small is-outline is-rounded"
      @click="confirmSuspensionDelete(reason, lateFeeSuspensionID)"
    >
      Delete
    </button>
  </div>
</template>

<script>
export default {
  props: {
    loadUpdateSuspension: Function,
    confirmSuspensionDelete: Function,
    reason: String,
    suspension: Object,
    lateFeeSuspensionID: [Number, String]
  }
}
</script>
