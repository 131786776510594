<template>
  <div suspension-modal>
    <div class="is-size-4" v-if="formData">{{ buttonText }} Late Fee Suspension</div>
    <ValidationObserver ref="formGroup" v-slot="{ handleSubmit }">
      <transition name="fade" mode="out-in" appear>
        <form @submit.prevent="handleSubmit(submitSuspension)">
          <fieldset>
            <div class="column is-12" v-if="suspension && suspension != undefined">
              <valid-input
                :disabled="true"
                style="width:50px !important;"
                name="AddedBy"
                label="Added By"
                maxlength="100"
                type="text"
                vid="AddedBy"
                placeholder="Added By"
                spellcheck="true"
                aria-label="Added By"
                rules="max:100|required"
                class="is-small"
                v-model="formData.addedBy"
              />
            </div>
            <div class="column is-12">
              <DatePicker
                v-model="formData.startDate"
                ariaLabel="Start Date"
                label="Start Date"
                ref="startDateComponent"
              />
            </div>
            <div class="column is-12">
              <DatePicker
                v-model="formData.endDate"
                ariaLabel="End Date"
                label="End Date"
                ref="endDateComponent"
              />
            </div>
            <div class="column is-12">
              <valid-input
                style="width:50px !important;"
                name="Reason"
                label="Reason"
                maxlength="50"
                type="text"
                vid="Reason"
                placeholder="Reason"
                spellcheck="true"
                aria-label="Reason"
                rules="max:100|required"
                class="is-small"
                v-model="formData.reason"
              />
            </div>
            <button
              type="submit"
              :disabled="loading"
              class="button is-primary is-rounded float-right"
            >
              {{ buttonText }}
            </button>
          </fieldset>
          <fieldset>
            <ValidationProvider vid="providerErrors" v-slot="{ errors }">
              <div class="field">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </fieldset>
        </form>
      </transition>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import ValidInput from '@/components/inputs/ValidInput'
import DatePicker from '@/components/inputs/DatePicker'

export default {
  props: {
    ownerID: Number,
    suspension: Object
  },

  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider,
    DatePicker
  },

  mounted() {
    if (this.isDebug == true) console.debug('ownerID=' + this.ownerID)
    this.reload()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,
  methods
}
</script>
