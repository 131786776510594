<template>
  <PageContentLayoutOnly :aria-label="$t('suspension.title')" role="region">
    <ModernTable
      :region="region"
      :filters="filters"
      :rows="rows"
      :columns="columns"
      :pagination="{
        perPage: 12
      }"
      :loading="loading"
      headerColor="#FFF"
      condensed
    >
      <template v-slot:header>
        <div>
          <button
            @click.prevent="addSuspension(selectedSuspension)"
            class="button is-primary is-rounded"
            :style="{ float: 'left' }"
          >
            Add Suspension
          </button>
        </div>
        <div :style="{ width: '100%', height: '60px' }">
          <b-button
            type="is-ghost"
            @click="filters.show = !filters.show"
            :style="{ float: 'right', background: 'none', border: 0 }"
            :class="'filter-button ' + (filters.show ? 'active' : '')"
          >
            <b-icon pack="mdi" icon="filter-outline" size="is-small" style="display: inline-block">
            </b-icon>
            <div style="display: inline-block">Filter</div>
          </b-button>
        </div>
      </template>
    </ModernTable>
    <Modal :toggle.sync="toggle">
      <SuspensionModal :suspension="selectedSuspension" :ownerID="ownerID" />
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import _get from 'lodash/get'
import { mapState, mapGetters } from 'vuex'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import { parseDate } from '@/utilities/Date/parse'
import { suspensionList } from '@/services/Units/Tabs/LateFeeSuspension/store'
import SuspensionActions from './component/SuspensionActions'
import SuspensionModal from './component/SuspensionModal'
import Modal from '@/components/Modal'

export default {
  name: 'LateFeeSuspension',
  components: {
    PageContentLayoutOnly,
    ModernTable,
    Modal,
    SuspensionModal
  },

  data: () => ({
    isDebug: true,
    loading: true,
    selectedSuspension: null,
    toggle: false,
    unitID: 0,
    radio: 'active',

    styles: {
      dateSelector: {
        display: 'flex',
        padding: '12px',
        textAlign: 'center',
        verticalAlign: 'middle',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'flex-end'
      },
      filterButton: {
        float: 'right',
        marginTop: '4px'
      }
    },

    region: {},
    filters: {
      show: false
    },
    rows: [],
    columns: [
      {
        field: 'startDate',
        label: 'Start Date',
        aria: 'Start Date',
        width: '15%',
        sortable: true,
        searchable: true
      },
      {
        field: 'endDate',
        label: 'End Date',
        aria: 'End Date',
        width: '15%',
        sortable: true,
        searchable: true
      },
      {
        field: 'reason',
        label: 'Reason',
        aria: 'Reason',
        width: '30%',
        sortable: true,
        searchable: true
      },
      {
        field: 'addedBy',
        label: 'Added By',
        aria: 'Added By',
        width: '10%',
        sortable: true,
        searchable: true
      },
      {
        field: 'actions',
        label: 'Actions',
        width: '15%'
      }
    ]
  }),

  created() {
    if (this.isDebug == true) console.debug('authUser=' + JSON.stringify(this.authUser))
    this.determineOwnerID()
  },

  methods: {
    determineOwnerID() {
      this.ownerID = 0

      if (this.authUser && this.authUser != undefined) {
        this.ownerID = _get(this, '$store.state.hoa.unit.primaryOwner.ownerID', null)
      }

      const fromOwnerID = _get(this.$route.query, 'fromOwnerID', 0)
      if (fromOwnerID > 0) {
        this.ownerID = fromOwnerID
        if (this.isDebug == true) console.debug('this.fromOwnerID=' + fromOwnerID)
      }

      if (this.isDebug == true) console.debug('this.ownerID=' + this.ownerID)
    },
    determineRows: function(list) {
      if (!Array.isArray(list)) {
        return
      }

      if (this.isDebug == true) console.debug('determineRows list=' + JSON.stringify(list))

      const dateFormat = 'LL'
      this.rows = []
      var rows = list.map(entry => {
        const lateFeeSuspensionID = _get(entry, 'lateFeeSuspensionID', '')
        const startDate = parseDate(_get(entry, 'startDate', ''), dateFormat)
        const endDate = parseDate(_get(entry, 'endDate', ''), dateFormat)
        const reason = _get(entry, 'reason', '')
        const addedBy = _get(entry, 'createdByIdentityInformation.name', '')

        return {
          startDate: startDate,
          endDate: endDate,
          reason: reason,
          addedBy: addedBy,
          actions: {
            component: SuspensionActions,
            props: {
              reason: reason,
              suspension: entry,
              lateFeeSuspensionID: lateFeeSuspensionID,
              loadUpdateSuspension: _suspension => {
                this.selectedSuspension = _suspension
                this.toggle = true
              },

              confirmSuspensionDelete: (_reason, _lateFeeSuspensionID) => {
                this.$buefy.dialog.confirm({
                  title: 'Deleting Suspension',
                  message: `Are you sure you want to <b>delete</b> this Late Fee Suspension, '${_reason}'?`,
                  confirmText: 'Delete Suspension',
                  type: 'is-danger',
                  hasIcon: true,
                  onConfirm: () => this.deleteSuspension(_lateFeeSuspensionID)
                })
              }
            }
          }
        }
      })

      this.rows = rows
    },

    addSuspension() {
      ;(this.selectedSuspension = null), (this.toggle = true)
    },

    async loadSuspension() {
      if (this.isDebug == true) console.debug('in loadSuspension()...')
      await suspensionList
        .dispatch('getSuspensionList', {
          hoaID: this.hoaId,
          ownerID: this.ownerID
        })
        .then(({ list }) => {
          this.determineRows(list)
        })
    },

    async deleteSuspension(id) {
      if (this.isDebug == true) console.debug('deleteSuspension id=' + id)

      await suspensionList.dispatch('deleteSuspension', {
        lateFeeSuspensionID: id,
        done: () => {
          this.loading = false
          this.reload()
        }
      })
    },

    reload() {
      this.loading = true
      this.rows = []
      if (this.unit && this.unit != undefined) {
        this.unitID = this.unit.unitID
      }

      this.loadSuspension()
      this.loading = false
    }
  },

  computed: {
    ...mapState({
      unit: state => state.hoa.unit,
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser', 'retrieveUser'])
    })
  },

  watch: {
    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  mounted() {
    if (this.authUser && this.authUser != undefined) {
      this.determineOwnerID()

      if (this.isDebug == true) console.debug('this.ownerID=' + this.ownerID)
    }
    this.reload()
  },

  i18n: {
    messages: {
      en: { suspension: { title: 'Late Fee Suspensions' } }
    }
  }
}
</script>

<style></style>
