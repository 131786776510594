/*
  import { suspensionStore } from '@/services/Units/Tabs/LateFeeSuspension/SuspensionModal/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  // Update Suspension
  async updateSuspension({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/AccountsReceivable/LateFeeSuspension`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.lateFeeSuspensionID > 0) {
        notifyMessage(`Successfully updated the selected late fee suspension.`)
        done({ details: results })
      } else {
        notifyError('There was a problem updating the selected late fee suspension.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem processing the update for this late fee suspension.`)
      console.error(exception)
    }
  },

  async addSuspension({ dispatch }, { ownerID, startDate, endDate, reason, done }) {
    if (isDebug == true) console.debug('...in addSuspension')
    try {
      const results = await kms.post(`/AccountsReceivable/LateFeeSuspension`, {
        ownerID,
        startDate,
        endDate,
        reason
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.lateFeeSuspensionID > 0) {
        notifyMessage(`Successfully added the late fee suspension.`)
        done({ details: results })
      } else {
        notifyError('There was a problem adding this late fee suspension.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem adding this late fee suspension.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const suspensionStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
